body {
  background-color: rgb(27, 27, 27);
  font-family: Courier New,Courier,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif;
  overflow: hidden;
  margin: 0;
  padding: 0;
}


.Main {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
}

.Main > ul {
  margin: 0;
  padding: 0;
  list-style: none;    
  position: relative;
  height: inherit;
}

.Main__slide {
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: 200ms opacity ease-in-out;
  transition-delay: 200ms;
}

.Main__slide > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.Main__slide[data-active="true"] {
  opacity: 1;
  z-index: 1;
  transition-delay: 0ms;
}

.Main__caption {
  position: absolute;
  font-weight: bold;
  color: white;
  bottom: 10%;
  left: 50%;
  font-size: 30px;
  transform: translateX(-50%);
  width: calc(100% - 64px);
}

.Main__date {
  color: red;
}

.Main__button {
  position: absolute;
  z-index: 2;
  background: none;
  border: none;
  font-size: 4rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, .5);
  cursor: pointer;
  padding: 0 .5rem;
}

.Main__button:hover,
.Main__button:focus {
  color: white;
}

.Main__button.prev {
  left: 1rem;
}

.Main__button.next {
  right: 1rem;
}

@media (max-width: 768px) {
  .Main__caption {
    font-size: 20px;
  }

  .Main__button {
    font-size: 2rem;
  }
}