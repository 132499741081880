body {
  background-color: #1b1b1b;
  margin: 0;
  padding: 0;
  font-family: Courier New, Courier, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  overflow: hidden;
}

.Main {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
}

.Main > ul {
  height: inherit;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.Main__slide {
  opacity: 0;
  transition: opacity .2s ease-in-out .2s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Main__slide > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}

.Main__slide[data-active="true"] {
  opacity: 1;
  z-index: 1;
  transition-delay: 0s;
}

.Main__caption {
  color: #fff;
  width: calc(100% - 64px);
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.Main__date {
  color: red;
}

.Main__button {
  z-index: 2;
  color: rgba(255, 255, 255, .5);
  cursor: pointer;
  background: none;
  border: none;
  padding: 0 .5rem;
  font-size: 4rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.Main__button:hover, .Main__button:focus {
  color: #fff;
}

.Main__button.prev {
  left: 1rem;
}

.Main__button.next {
  right: 1rem;
}

@media (max-width: 768px) {
  .Main__caption {
    font-size: 20px;
  }

  .Main__button {
    font-size: 2rem;
  }
}

/*# sourceMappingURL=index.6ab32d97.css.map */
